<template>
  <section class="A4 sheet sheet-transaction">
    <div>
      <!-- Start: Logo & Title -->
      <div class="d-flex align-center justify-space-between">
        <div>
          <div class="sheet-logo--container">
            <img
              :src="LIST_MODEL_SITES[paymentTransaction.site].logo"
              alt="Logo"
              width="136px"
            />
          </div>
          <div class="mt-2" style="max-width: 250px">
            <div class="sheet-font--bold">Chi nhánh</div>
            <div>
              {{
                paymentTransaction.branch
                  ? paymentTransaction.branch.address
                  : "N/A"
              }}
            </div>
          </div>
        </div>
        <div class="d-flex">
          <div>
            <div class="sheet-title sheet-font--bold">PHIẾU THU ĐẶT CỌC</div>
            <div class="mt-2">
              <div class="d-flex align-center justify-space-between">
                <div>Đơn hàng</div>
                <div class="sheet-font--bold">
                  {{
                    paymentTransaction.transactionable
                      ? paymentTransaction.transactionable.code
                      : "N/A"
                  }}
                </div>
              </div>
              <div class="d-flex align-center justify-space-between">
                <div>Phiếu thu</div>
                <div class="sheet-font--bold">
                  {{ paymentTransaction.code }}
                </div>
              </div>
              <div class="d-flex align-center justify-space-between">
                <div>Ngày ghi</div>
                <div class="sheet-font--bold">
                  {{ paymentTransaction.created_at | formatTime }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- End: Logo & Title -->
      <div class="sheet-divider my-4"></div>
      <!-- Start: Customer info -->
      <div>
        <div class="sheet-font--bold">THÔNG TIN KHÁCH HÀNG</div>
        <div class="d-flex">
          <div class="mr-8">
            <span class="sheet-font--bold">Họ & Tên: </span>
            <span>{{
              paymentTransaction.senderable
                ? paymentTransaction.senderable.name
                : "N/A"
            }}</span>
          </div>
          <div>
            <span class="sheet-font--bold">SĐT: </span>
            <span
              v-if="
                paymentTransaction.senderable &&
                  paymentTransaction.senderable.phone
              "
            >
              {{ paymentTransaction.senderable.phone | VMask("### ### ####") }}
            </span>
          </div>
        </div>
      </div>
      <!-- End: Customer info -->
      <div class="sheet-divider my-4"></div>
      <!-- Start: Payment info -->
      <div>
        <div class="sheet-font--bold">THÔNG TIN THU</div>
        <div class="d-flex justify-space-between">
          <div class="">
            <div>
              <span class="sheet-font--bold">Nhân viên thu: </span>
              <span>
                {{
                  paymentTransaction.created_by_user
                    ? paymentTransaction.created_by_user.name
                    : "N/A"
                }}
              </span>
            </div>
            <div class="d-flex">
              <div class="sheet-font--bold">Ngày dự kiến giao hàng:</div>
              <div class="dot-line--est"></div>
            </div>
          </div>
          <div class="d-flex" style="width: 272px">
            <span class="sheet-font--bold mr-2">Số tiền: </span>
            <span class="payment-amount font-weight-black">
              {{ Number(paymentTransaction.amount) | formatCurrency("VND") }}
            </span>
          </div>
        </div>
      </div>
      <!-- End: Payment info -->
      <!-- Start: Products -->
      <div class="sheet-products mt-5">
        <!-- Start: Products list -->
        <table>
          <thead>
            <tr>
              <th class="text-left">SẢN PHẨM</th>
              <th>ĐƠN GIÁ</th>
              <th>CHIẾT KHẤU</th>
              <th>SỐ LƯỢNG</th>
              <th class="sheet-text--right">THÀNH TIỀN</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="item in orderOption" :key="item.id">
              <td class="pr-2" style="max-width: 240px">
                <div class="sheet-font--bold">
                  {{ item.option && item.option.name }}
                </div>
                <div class="sheet-text--small">
                  <span class="sheet-text--bold"
                    >SKU: {{ item.option && item.option.SKU }}</span
                  >

                  <div>{{ item.option.specs }}</div>
                </div>
              </td>
              <td class="sheet-text--center">
                {{ item.unit_price | formatCurrency("VND") }}
              </td>
              <td class="sheet-text--center">
                {{ item.rebate_amount | formatCurrency("VND") }}
              </td>
              <td class="sheet-text--center">{{ item.qty }}</td>
              <td class="sheet-text--right">
                {{
                  ((item.unit_price - item.rebate_amount) * item.qty)
                    | formatCurrency("VND")
                }}
              </td>
            </tr>
          </tbody>
        </table>
        <!-- End: Products list-->
        <div class="sheet-divider my-3"></div>
        <!-- Start: Amounts -->
        <div class="d-flex justify-end">
          <div class="sheet-amounts">
            <div class="d-flex justify-space-between">
              <div>Tổng tiền hàng</div>
              <div>
                {{ orderGoodsTotal | formatCurrency("VND") }}
              </div>
            </div>
            <div class="d-flex justify-space-between">
              <div>Phí vận chuyển</div>
              <div>
                {{
                  paymentTransaction.transactionable &&
                  paymentTransaction.transactionable.shipping_fee
                    ? Number(paymentTransaction.transactionable.shipping_fee)
                    : 0 | formatCurrency("VND")
                }}
              </div>
            </div>
            <div class="d-flex justify-space-between">
              <div>Giảm giá đơn hàng</div>
              <div>
                -{{
                  paymentTransaction.transactionable &&
                  paymentTransaction.transactionable.discount
                    ? Number(paymentTransaction.transactionable.discount)
                    : 0 | formatCurrency("VND") | formatCurrency("VND")
                }}
              </div>
            </div>
            <div
              class="d-flex justify-space-between sheet-font--bold text-large"
            >
              <div>Khách cần trả</div>
              <div>{{ Number(orderValue) | formatCurrency("VND") }}</div>
            </div>
            <div class="d-flex justify-space-between">
              <div>Đã thanh toán</div>
              <div>
                {{
                  paymentTransaction.transactionable.sum_all_transaction
                    | formatCurrency("VND")
                }}
              </div>
            </div>
            <div class="d-flex justify-space-between">
              <div>Còn nợ</div>
              <div>
                {{
                  (Number(orderValue) -
                    paymentTransaction.transactionable.sum_all_transaction)
                    | formatCurrency("VND")
                }}
              </div>
            </div>
          </div>
        </div>
        <!-- End: Amounts -->
      </div>
      <!-- End: Products -->
    </div>

    <div>
      <div class="sheet-divider my-4"></div>
      <!-- Start: Responsibility -->
      <div class="sheet-responsibility">
        <div class="sheet-font--bold">TRÁCH NHIỆM VÀ CAM KẾT</div>

        <ul>
          <li class="text-content">
            <div class="text-back">
              Phiếu đặt hàng đảm bảo Quý khách sẽ được mua hàng khi hàng có sẵn
              trên hệ thống của
              {{ LIST_MODEL_SITES[paymentTransaction.site].text }}
              theo đúng thứ tự.
            </div>
          </li>
          <li class="text-content">
            <div class="text-back">
              {{ LIST_MODEL_SITES[paymentTransaction.site].text }}
              có trách nhiệm đảm bảo có hàng cho quý khách.
            </div>
          </li>
          <li class="text-content">
            <div class="text-back">
              Trong trường hợp không có hàng (trừ trường hợp hàng về nhưng do
              điều kiện khách quan hoặc quy cách sai lệch),
              {{ LIST_MODEL_SITES[paymentTransaction.site].text }}
              sẽ hoàn lại cho quý khách 102% số tiền đã đặt cọc ban đầu.
            </div>
          </li>
        </ul>

        <div class="d-flex cashback-option">
          <div class="col cashback-option--item px-0 pt-5 pb-0">
            <div class="d-flex align-center justify-center sheet-font--bold">
              <div class="cashback-option--item-dot mr-2"></div>
              <div>HOÀN TIỀN</div>
              <div class="line"></div>
              <div>(%)</div>
            </div>
          </div>
          <div class="col cashback-option--item px-0 pt-5 pb-0">
            <div class="d-flex align-center justify-center sheet-font--bold">
              <div class="cashback-option--item-dot mr-2"></div>
              <div>KHÔNG HOÀN TIỀN</div>
            </div>
          </div>
        </div>
      </div>
      <!-- End: Responsibility -->
      <div class="sheet-divider my-4"></div>
      <!-- Start: Signature -->
      <div class="d-flex sheet-signatures mb-4">
        <div class="col sheet-text--center pa-0">
          <div class="sheet-font--bold">
            XÁC NHẬN TỪ
            {{ LIST_MODEL_SITES[paymentTransaction.site].id }}
          </div>
          <div>(Chữ ký, đóng dấu)</div>
        </div>
        <div class="col sheet-text--center pa-0">
          <div class="sheet-font--bold">XÁC NHẬN TỪ KHÁCH HÀNG</div>
          <div>(Chữ ký, Họ & Tên)</div>
        </div>
      </div>
      <!-- End: Signature -->
      <div>
        <div class="sheet-divider my-3"></div>
        <!-- Start: Contacts -->
        <div class="d-flex sheet-contact">
          <div class="col sheet-font--bold pa-0">LIÊN HỆ VỚI CHÚNG TÔI</div>
          <div class="col pa-0"><strong>Hotline:</strong> 1900 633 579</div>
          <div class="col sheet-text--center pa-0">
            <strong>Website:</strong>
            {{ removeHttps(LIST_MODEL_SITES[paymentTransaction.site].url) }}
          </div>
          <div class="col sheet-text--right pa-0">
            <strong>Fanpage:</strong>
            fb.com/{{
              removeHttps(LIST_MODEL_SITES[paymentTransaction.site].url)
            }}
          </div>
        </div>
        <!-- End: Contacts -->
        <div class="sheet-divider my-3"></div>
        <!-- Start: Stores -->
        <div class="d-flex sheet-stores">
          <div class="col-3 sheet-font--bold pa-0">HỆ THỐNG CỬA HÀNG</div>
          <div class="col-4 pa-0">
            <div class="d-flex">
              <div class="sheet-font--bold">Hà Nội:</div>
              <ul>
                <li><span class="text-back">53 Thái Hà, Q. Đống Đa</span></li>
              </ul>
            </div>
          </div>
          <div class="col-5 pa-0">
            <div class="d-flex justify-end">
              <div class="sheet-font--bold">TP.HCM:</div>
              <ul>
                <li>
                  <div class="text-back">
                    5-7 Nguyễn Huy Tưởng, P. 6, Q. Bình Thạnh
                  </div>
                </li>
                <li>
                  <div class="text-back">95 Trần Thiện Chánh, P. 12, Q. 10</div>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <!-- End: Stores -->
      </div>
    </div>
  </section>
</template>

<script>
import { LIST_MODEL_SITES } from "@/core/constant";

export default {
  data() {
    return {
      LIST_MODEL_SITES: LIST_MODEL_SITES
    };
  },

  mounted() {
    const route = this.$route;

    this.$store.dispatch(
      "PAYMENT_TRANSACTION/getPaymentTransactionById",
      route.params.paymentTransactionId
    );

    if (this.paymentTransaction && this.paymentTransaction.transactionable) {
      this.$store.dispatch(
        "ORDER/getOrderPaymentTransactions",
        this.paymentTransaction.transactionable.id
      );
    }

    setTimeout(() => {
      this.printPage();
    }, 1000);
  },
  computed: {
    orderGoodsTotal() {
      if (
        !this.paymentTransaction ||
        (this.paymentTransaction && !this.paymentTransaction.transactionable)
      )
        return 0;

      return this.paymentTransaction.transactionable.details.reduce(
        (total, item) => {
          const giftTotal = item.gifts.reduce(
            (giftTotal, gift) =>
              giftTotal + gift.qty * (gift.unit_price - gift.rebate_amount),
            0
          );

          return (
            total +
            item.qty * (item.unit_price - item.rebate_amount) +
            giftTotal
          );
        },
        0
      );
    },

    orderOption() {
      if (
        !this.paymentTransaction ||
        (this.paymentTransaction && !this.paymentTransaction.transactionable)
      )
        return [];

      return this.paymentTransaction.transactionable.details.reduce(
        (arr, option) => {
          arr.push(option);

          if (option.gifts.length > 0) {
            arr = arr.concat(option.gifts);
          }

          return arr;
        },
        []
      );
    },

    orderThuTotal() {
      return this.$store.getters["ORDER/orderThuTotal"];
    },

    orderValue() {
      if (
        !this.paymentTransaction ||
        (this.paymentTransaction && !this.paymentTransaction.transactionable)
      )
        return 0;

      return this.paymentTransaction.transactionable.value;
    },

    paymentTransaction() {
      return this.$store.getters["PAYMENT_TRANSACTION/paymentTransaction"];
    }
  },

  filters: {
    formatTime(d) {
      const dateTime = new Date(d),
        hour = String(dateTime.getHours()).padStart(2, "0"),
        min = String(dateTime.getMinutes()).padStart(2, "0"),
        date = String(dateTime.getDate()).padStart(2, "0"),
        month = String(dateTime.getMonth() + 1).padStart(2, "0"),
        year = dateTime.getFullYear();

      return `${date}/${month}/${year} ${hour}:${min}`;
    }
  },

  methods: {
    printPage() {
      this.$nextTick(() => {
        document.title =
          this.paymentTransaction.transactionable.code +
          "-" +
          this.paymentTransaction.code;

        window.print();
      });
    },

    removeHttps(url) {
      return url.replace("https://", "");
    }
  }
};
</script>

<style lang="scss" scoped>
.sheet-transaction {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.payment-amount {
  font-size: 24px;
  font-weight: 600;
  margin-top: -4px;
}

.sheet-signatures {
  min-height: 100px;
}
</style>
